import React from "react";
import { Briefcase, MessageSquare, User } from "lucide-react";
import { Link } from "react-router-dom";

const Sidebar = ({ active, setActive, sections, username }) => {
  const icons = [<Briefcase key="0" />, <MessageSquare key="1" />, <User key="2" />];

  return (
    <div
      style={{
        width: "250px",
        backgroundColor: "#333",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ padding: "20px", fontSize: "1.5rem", fontWeight: "bold" }}>Dashboard</div>
      <ul style={{ listStyle: "none", padding: "10px", flex: 1 }}>
        {sections.map((section, index) => (
          <li
            key={index}
            onClick={() => setActive(index)}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "10px",
              marginBottom: "5px",
              cursor: "pointer",
              borderRadius: "5px",
              backgroundColor: active === index ? "#555" : "transparent",
              userSelect: "none",
            }}
          >
            {icons[index]}
            {section}
          </li>
        ))}
      </ul>
      <div
        style={{
          padding: "20px",
          borderTop: "1px solid #555",
          textAlign: "center",
        }}
      > {username}
        <Link
          to="/api/logout.php"
          onClick={() => setTimeout(() => { window.location.reload() }, 100)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: "#E53935",
            color: "white",
            padding: "8px 15px",
            marginTop: "5px",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
            textDecoration: "none",
          }}
        >
          Logout
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
