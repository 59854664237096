import React, { useState, useEffect } from "react";
import { useMainContext } from "../context/MainContext";
import Sidebar from "../components/Sidebar";
import Table from "../components/Table";
import Modal from "../components/Modal";

const API_URL = "/api/actions.php";

const Dashboard = () => {
  const { user } = useMainContext();

  const [active, setActive] = useState(0);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalType, setModalType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [newImageFile, setNewImageFile] = useState(null);
  const [removeImage, setRemoveImage] = useState(false);

  console.log("Data: ", data)
  console.log("FormData: ", formData)

  const sectionTitles = ["Trips", "Messages", "Admins"];

  const getColumnsForSection = (index) => {
    switch (index) {
      case 0:
        return ["id", "title", "description", "image", "book_link", "hidden", "available", "available_from", "expires_on"];
      case 1:
        return ["id", "firstname", "lastname", "email", "trip", "message", "received_at"];
      case 2:
        return ["id", "username", "password_hash", "created_at"];
      default:
        return [];
    }
  };

  const fetchData = async () => {
    let action = "";
    if (active === 0) action = "view_all_trips";
    else if (active === 1) action = "view_messages";
    else if (active === 2) action = "view_all_admins";

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ action }),
      });
      const result = await response.json();
      if (result.status === "error") {
        alert(result.message);
        return;
      }
      if (result.status === "success") {
        setData(result.data || []);
        setColumns(getColumnsForSection(active));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [active]);

  const handleAction = (actionType, row = null) => {
    setModalType(actionType);
    setSelectedRow(row);
    setNewImageFile(null);
    setRemoveImage(false);

    const cols = getColumnsForSection(active);
    if (actionType === "add") {
      const initial = {};
      cols.forEach((col) => {
        if (col === "id") return;
        if (active === 2 && (col === "password_hash" || col === "created_at")) return;
        initial[col] = "";
      });
      if (active === 2) {
        initial["password"] = "";
      }
      if (active === 0) {
        initial["title_fi"] = "";
        initial["title_en"] = "";
        initial["description_fi"] = "";
        initial["description_en"] = "";
      }
      setFormData(initial);
    } else if (actionType === "modify" && row) {
      const initial = {};
      for (const col of cols) {
        if (col === "id") {
          initial["id"] = row.id;
          continue;
        }
        if (active === 2 && (col === "password_hash" || col === "created_at")) continue;
        initial[col] = row[col] || "";
      }
      if (active === 2) {
        initial["password"] = "";
      }
      if (active === 0) {
        let titleObj = {};
        let descObj = {};
        try { titleObj = JSON.parse(initial["title"] || "{}"); } catch { }
        try { descObj = JSON.parse(initial["description"] || "{}"); } catch { }

        initial["title_fi"] = titleObj.fi || "";
        initial["title_en"] = titleObj.en || "";
        initial["description_fi"] = descObj.fi || "";
        initial["description_en"] = descObj.en || "";
      }
      setFormData(initial);
    }

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
    setModalType("");
  };

  const validateFormData = () => {
    if (active === 0) {
      // Validate dates
      if (!formData["available_from"]) {
        alert("Available From date is required.");
        return false;
      }

      // Validate book_link as URL if not empty
      if (formData["book_link"]) {
        try {
          new URL(formData["book_link"]);
        } catch (e) {
          alert("Invalid Book Link URL provided.");
          return false;
        }
      }
    }
    return true;
  };

  const saveChanges = async () => {
    if (!validateFormData()) return;

    let action = "";
    if (active === 0) {
      action = modalType === "add" ? "add_trip" : "update_trip";
    } else if (active === 1) {
      action = modalType === "add" ? "add_message" : "update_message";
    } else if (active === 2) {
      action = modalType === "add" ? "add_admin" : "update_admin";
    }

    if (active === 0) {
      // Validate Input Before Continuing
      if (!formData["title_fi"] || !formData["title_en"]) {
        alert("Title is required in both languages.");
        return;
      }
      if (!formData["description_fi"] || !formData["description_en"]) {
        alert("Description is required in both languages.");
        return;
      }
      if (!formData["available_from"]) {
        alert("Available From date is required.");
        return;
      }
      if (!formData["book_link"]) {
        alert("Book Link is required.");
        return;
      }

      const titleJSON = { fi: formData["title_fi"] || "", en: formData["title_en"] || "" };
      const descJSON = { fi: formData["description_fi"] || "", en: formData["description_en"] || "" };
      formData["title"] = JSON.stringify(titleJSON);
      formData["description"] = JSON.stringify(descJSON);
      delete formData["title_fi"];
      delete formData["title_en"];
      delete formData["description_fi"];
      delete formData["description_en"];
    }

    let isMultipart = false;
    if (active === 0 && (newImageFile || removeImage)) {
      isMultipart = true;
    }

    let requestBody;
    let requestHeaders = {};

    if (isMultipart) {
      const form = new FormData();
      form.append("action", action);
      for (const col in formData) {
        form.append(col, formData[col]);
      }
      if (newImageFile) {
        form.append("image", newImageFile);
      }
      if (removeImage) {
        form.append("remove_image", "true");
      }
      requestBody = form;
    } else {
      const payload = { action, data: { ...formData } };
      requestBody = JSON.stringify(payload);
      requestHeaders["Content-Type"] = "application/json";
    }

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: { ...requestHeaders },
        body: requestBody,
      });
      const result = await response.json();
      if (result.status === 'error') {
        alert(result.message);
        return;
      }
      if (result.status === 'success') {
        fetchData();
      }
    } catch (error) {
      console.error("Error saving changes:", error);
    }

    closeModal();
  };

  const confirmDelete = async () => {
    let action = "";
    if (active === 0) action = "delete_trip";
    else if (active === 1) action = "delete_message";
    else if (active === 2) action = "delete_admin";

    if (!selectedRow || !selectedRow.id) {
      alert("No ID provided for deletion.");
      return;
    }

    const payload = { action, data: { id: selectedRow.id } };

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      if (result.status === 'error') {
        alert(result.message);
        return;
      }
      if (result.status === 'success') {
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }

    closeModal();
  };

  let showCancel = true;
  let showSave = true;
  let cancelText = "Cancel";
  let saveText = "Save";
  let cancelColor = "red";
  let saveColor = "green";

  if (modalType === "view") {
    showCancel = false;
    showSave = true;
    saveText = "Close";
    saveColor = "orange";
  } else if (modalType === "modify") {
    saveText = "Save";
    saveColor = "orange";
    cancelText = "Cancel";
    cancelColor = "red";
  } else if (modalType === "delete") {
    saveText = "Delete";
    saveColor = "red";
    cancelText = "Close";
    cancelColor = "orange";
  } else if (modalType === "add") {
    saveText = "Save";
    saveColor = "green";
    cancelText = "Close";
    cancelColor = "orange";
  }

  let displayColumns = columns;
  if (active === 2) {
    displayColumns = columns.filter(c => c !== 'password_hash');
  }

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Sidebar active={active} setActive={setActive} sections={sectionTitles} username={user}/>
      <div style={{ flex: 1, padding: "20px", overflowX: "auto" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
          <h2>{sectionTitles[active]}</h2>
          {(active === 0 || active === 2) && (
            <button
              onClick={() => handleAction("add")}
              style={{
                backgroundColor: "#66BB6A",
                color: "#FFFFFF",
                fontWeight: "bold",
                padding: "10px 15px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
              }}
            >
              Add {sectionTitles[active].slice(0, -1)}
            </button>
          )}
        </div>
        <Table
          data={data}
          columns={displayColumns}
          onView={(row) => handleAction("view", row)}
          onModify={(row) => handleAction("modify", row)}
          onDelete={(row) => handleAction("delete", row)}
          activeSection={active}
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onCancel={closeModal}
        onClose={modalType === "delete" ? confirmDelete : (modalType === "modify" || modalType === "add") ? saveChanges : closeModal}
        showCancel={showCancel}
        cancelText={cancelText}
        showSave={showSave}
        saveText={saveText}
        cancelColor={cancelColor}
        saveColor={saveColor}
        closeOnOverlayClick={true}
      >

        {modalType === "view" && selectedRow && (
          <div>
            <h3 style={{ marginBottom: "20px" }}>View {sectionTitles[active].slice(0, -1)}</h3>
            <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
              {Object.entries(selectedRow).map(([key, value]) => {
                if (active === 0 && key === "title") {
                  let titleObj = {};
                  try {
                    titleObj = JSON.parse(value || "{}");
                  } catch { }
                  return (
                    <div key={key} style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                      <div style={{ flex: "1 1 45%" }}>
                        <label style={{ fontWeight: "bold", marginBottom: "5px" }}>Title (EN)</label>
                        <p style={{ backgroundColor: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>
                          {titleObj.en || "N/A"}
                        </p>
                      </div>
                      <div style={{ flex: "1 1 45%" }}>
                        <label style={{ fontWeight: "bold", marginBottom: "5px" }}>Title (FI)</label>
                        <p style={{ backgroundColor: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>
                          {titleObj.fi || "N/A"}
                        </p>
                      </div>
                    </div>
                  );
                } else if (active === 0 && key === "description") {
                  let descObj = {};
                  try {
                    descObj = JSON.parse(value || "{}");
                  } catch { }
                  return (
                    <div key={key} style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                      <div style={{ flex: "1 1 45%" }}>
                        <label style={{ fontWeight: "bold", marginBottom: "5px" }}>Description (EN)</label>
                        <div
                          style={{
                            backgroundColor: "#f9f9f9",
                            padding: "10px",
                            borderRadius: "5px",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {descObj.en || "N/A"}
                        </div>
                      </div>
                      <div style={{ flex: "1 1 45%" }}>
                        <label style={{ fontWeight: "bold", marginBottom: "5px" }}>Description (FI)</label>
                        <div
                          style={{
                            backgroundColor: "#f9f9f9",
                            padding: "10px",
                            borderRadius: "5px",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {descObj.fi || "N/A"}
                        </div>
                      </div>
                    </div>
                  );
                } else if (active === 0 && (key === "hidden" || key === "available")) {
                  const val = value == 1 || value === "1" ? "Yes" : "No";
                  return (
                    <div key={key} style={{ display: "flex", flex: "1 1 100%", marginBottom: "10px" }}>
                      <div style={{ flex: "1 1 200px" }}>
                        <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                          {key.charAt(0).toUpperCase() + key.slice(1)}
                        </label>
                        <p style={{ backgroundColor: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>{val}</p>
                      </div>
                    </div>
                  );
                } else if (key === "book_link") {
                  return (
                    <div key={key} style={{ flex: "1 1 100%", marginBottom: "10px" }}>
                      <label style={{ fontWeight: "bold", marginBottom: "5px" }}>Booking Link</label>
                      <a
                        href={value || "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "block",
                          backgroundColor: "#f9f9f9",
                          padding: "10px",
                          borderRadius: "5px",
                          textDecoration: "none",
                          color: "#007BFF",
                        }}
                      >
                        {value || "N/A"}
                      </a>
                    </div>
                  );
                } else if (key === "image") {
                  return (
                    <div key={key} style={{ flex: "1 1 100%", marginBottom: "10px" }}>
                      <label style={{ fontWeight: "bold", marginBottom: "5px" }}>Image</label>
                      {value ? (
                        <img
                          src={value}
                          alt="Trip"
                          style={{
                            display: "block",
                            maxWidth: "100%",
                            maxHeight: "200px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                          }}
                        />
                      ) : (
                        <p style={{ backgroundColor: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>
                          No Image Available
                        </p>
                      )}
                    </div>
                  );
                } else if (active === 0 && (key === "available_from" || key === "expires_on")) {
                  return (
                    <div key={key} style={{ flex: "1 1 45%", marginBottom: "10px" }}>
                      <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                        {key.charAt(0).toUpperCase() + key.replace("_", " ")}
                      </label>
                      <p style={{ backgroundColor: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>
                        {value || "N/A"}
                      </p>
                    </div>
                  );
                } else {
                  return (
                    <div key={key} style={{ flex: "1 1 45%", marginBottom: "10px" }}>
                      <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                        {key.charAt(0).toUpperCase() + key.replace("_", " ")}
                      </label>
                      <p style={{ backgroundColor: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>
                        {value || "N/A"}
                      </p>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}



        {(modalType === "modify" || modalType === "add") && (
          <div style={{ marginBottom: "20px" }}>
            <h3 style={{ marginBottom: "20px" }}>
              {modalType === "modify" ? "Edit" : "Add"} {sectionTitles[active].slice(0, -1)}
            </h3>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", marginBottom: "20px" }}>
              {getColumnsForSection(active)
                .filter((col) => col !== "id" && !(active === 2 && (col === "password_hash" || col === "created_at")))
                .map((col) => {
                  if (active === 0 && (col === "title" || col === "description")) {
                    return null;
                  }
                  if (active === 0 && col === "image") {
                    const currentImage = selectedRow && selectedRow.image && !removeImage && !newImageFile ? selectedRow.image : null;
                    return (
                      <div key={col} style={{ marginBottom: "20px", flex: "1 1 100%" }}>
                        <label style={{ display: "block", fontWeight: "bold", marginBottom: "5px" }}>Image</label>
                        {currentImage && (
                          <>
                            <img src={currentImage} alt="Trip" style={{ maxHeight: "100px", marginBottom: "10px" }} />
                            <div style={{ marginBottom: "10px" }}>
                              <button
                                type="button"
                                onClick={() => {
                                  setRemoveImage(true);
                                  setNewImageFile(null);
                                }}
                                style={{
                                  backgroundColor: "#E53935",
                                  color: "white",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  border: "none",
                                  cursor: "pointer",
                                  marginRight: "10px",
                                }}
                              >
                                Remove Image
                              </button>
                              <span style={{ marginRight: "10px" }}>or select new one:</span>
                            </div>
                          </>
                        )}
                        {removeImage && <p style={{ fontStyle: "italic" }}>Image will be removed.</p>}
                        {!removeImage && (
                          <input
                            type="file"
                            onChange={(e) => {
                              setNewImageFile(e.target.files[0] || null);
                              if (e.target.files[0]) setRemoveImage(false);
                            }}
                            style={{ width: "100%", marginBottom: "10px" }}
                          />
                        )}
                      </div>
                    );
                  } else if (active === 0 && (col === "available_from" || col === "expires_on")) {
                    return (
                      <div key={col} style={{ marginBottom: "20px", flex: "1 1 200px" }}>
                        <label style={{ display: "block", fontWeight: "bold", marginBottom: "5px" }}>
                          {col.charAt(0).toUpperCase() + col.slice(1)}
                        </label>
                        <input
                          type="date"
                          value={formData[col] || ""}
                          onChange={(e) => setFormData({ ...formData, [col]: e.target.value })}
                          style={{
                            width: "100%",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                          }}
                        />
                      </div>
                    );
                  } else if (active === 0 && (col === "hidden" || col === "available")) {
                    const val = formData[col] || "0";
                    return (
                      <div key={col} style={{ marginBottom: "20px", flex: "1 1 200px" }}>
                        <label style={{ display: "block", fontWeight: "bold", marginBottom: "5px" }}>
                          {col.charAt(0).toUpperCase() + col.slice(1)}
                        </label>
                        <div style={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "5px" }}>
                          <label>
                            <input
                              type="radio"
                              name={col}
                              value="1"
                              checked={val === "1"}
                              onChange={() => setFormData({ ...formData, [col]: "1" })}
                            />{" "}
                            Yes
                          </label>
                          <label>
                            <input
                              type="radio"
                              name={col}
                              value="0"
                              checked={val === "0"}
                              onChange={() => setFormData({ ...formData, [col]: "0" })}
                            />{" "}
                            No
                          </label>
                        </div>
                        <p style={{ fontStyle: 'italic', fontSize: '0.9em' }}>
                          {col === "available"
                            ? "'Available' means the trip is available for booking."
                            : "'Hidden' means the trip is hidden and not accessible."}
                        </p>
                      </div>
                    );
                  } else if (active === 2 && col === "password") {
                    return (
                      <div key={col} style={{ marginBottom: "20px", flex: "1 1 200px" }}>
                        <label style={{ display: "block", fontWeight: "bold", marginBottom: "5px" }}>Password</label>
                        <input
                          type="password"
                          value={formData["password"] || ""}
                          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                          style={{
                            width: "100%",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                          }}
                        />
                        {modalType === "modify" && <small>If left empty, password will not change.</small>}
                      </div>
                    );
                  } else {
                    return (
                      <div key={col} style={{ marginBottom: "20px", flex: "1 1 200px" }}>
                        <label style={{ display: "block", fontWeight: "bold", marginBottom: "5px" }}>
                          {col.charAt(0).toUpperCase() + col.slice(1)}
                        </label>
                        <input
                          type={active === 2 && col === "password" ? "password" : "text"}
                          value={formData[col] || ""}
                          onChange={(e) => setFormData({ ...formData, [col]: e.target.value })}
                          style={{
                            width: "100%",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                          }}
                        />
                      </div>
                    );
                  }
                })}
            </div>

            {active === 2 && modalType === "add" && (
              <div style={{ marginBottom: "20px" }}>
                <label style={{ display: "block", fontWeight: "bold", marginBottom: "5px" }}>Password</label>
                <input
                  type="password"
                  value={formData["password"] || ""}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
            )}

            {active === 0 && (
              <div style={{ marginTop: "20px" }}>
                <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
                  <div style={{ flex: "1 1 200px" }}>
                    <label style={{ display: "block", fontWeight: "bold", marginBottom: "5px" }}>Title (FI)</label>
                    <input
                      type="text"
                      value={formData["title_fi"] || ""}
                      onChange={(e) => setFormData({ ...formData, title_fi: e.target.value })}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    />
                  </div>
                  <div style={{ flex: "1 1 200px" }}>
                    <label style={{ display: "block", fontWeight: "bold", marginBottom: "5px" }}>Title (EN)</label>
                    <input
                      type="text"
                      value={formData["title_en"] || ""}
                      onChange={(e) => setFormData({ ...formData, title_en: e.target.value })}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
                  <div style={{ flex: "1 1 200px" }}>
                    <label style={{ display: "block", fontWeight: "bold", marginBottom: "5px" }}>Description (FI)</label>
                    <textarea
                      value={formData["description_fi"] || ""}
                      onChange={(e) => setFormData({ ...formData, description_fi: e.target.value })}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        minHeight: "100px"
                      }}
                    />
                  </div>
                  <div style={{ flex: "1 1 200px" }}>
                    <label style={{ display: "block", fontWeight: "bold", marginBottom: "5px" }}>Description (EN)</label>
                    <textarea
                      value={formData["description_en"] || ""}
                      onChange={(e) => setFormData({ ...formData, description_en: e.target.value })}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        minHeight: "100px"
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {modalType === "delete" && (
          <div style={{ marginBottom: "20px" }}>
            <h3 style={{ marginBottom: "20px" }}>Confirm Deletion</h3>
            <p>Are you sure you want to delete this {sectionTitles[active].slice(0, -1)}?</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Dashboard;
