import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { useMainContext } from '../context/MainContext'
import BackgroundImage from '../assets/background.jpg'

import Footer from '../components/Footer'
import Space from '../components/Space'
import Card from '../components/Card'
import CardImage from '../assets/card-trip.jpg'
import PidammePuhtaana from '../assets/pidamme-puhtaana.jpg'

const Home = () => {
   const localization = useMainContext().localization.pages.home
   const trips = useMainContext().trips

   console.log("Trips: ", trips)

   const tripSection = useRef(null)

   const handleTripScroll = () => {
      if (tripSection.current) {
         tripSection.current.scrollIntoView({ behavior: 'smooth' })
      }
   }

   return (
      <div style={styles.container}>
         <div style={{ ...styles.top, backgroundImage: `url(${BackgroundImage})` }}>
            <div style={styles.topTextBackground}>
               <Link style={styles.topButton} onClick={handleTripScroll}>{localization.button}</Link>
            </div>
         </div>

         <Space value={80} />

         <div ref={tripSection} style={styles.middle}>
            <div>
               <Space value={10} />
               <h1>{localization.title}</h1>

               <Space value={20} />
               <p>{localization.content.first}</p>
            </div>

            <Space value={40} />

            <div style={styles.tripViewContainer}>
               {trips.map((trip) => (
                  <Card key={trip.id} image={`https://www.playcode.me/${trip.image}` || CardImage} redirect={`trip-view`} id={trip.id} />
               ))}

               {trips.length === 0 && <p>{localization.content.noTrips}</p>}
            </div>

            <Space value={80} />

            <p>{localization.content.second}</p>

            <Space value={20} />
            <div style={{ ...styles.lastImage, backgroundImage: `url(${PidammePuhtaana})` }}></div>
         </div>

         <Space value={80} />

         <Footer />
      </div>
   )
}

const styles = {
   container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',

      backgroundColor: "#E8E8E8" // Slightly darker
   },
   top: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      minWidth: '100%',
   },
   middle: {
      width: '65%'
   },
   topButton: {
      color: '#F0F0F0', // Not pure white
      fontSize: '1.2rem',
      fontWeight: 600,
      textDecoration: 'none',
      backgroundColor: '#DD9933',
      padding: '10px 20px',
      borderRadius: '10px',
   },
   lastImage: {
      width: '100%',
      minHeight: 450,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: 10,
   },
   tripViewContainer: {
      display: 'grid',
      justifyContent: 'center',
      gridTemplateColumns: `repeat(auto-fit, minmax(${window.innerWidth <= 820 ? 200 : 300}px, ${window.innerWidth <= 820 ? 1 : 0.3}fr))`,
      gap: 20,
   }
}

export default Home