import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMainContext } from "../context/MainContext";

import FIFlag from "../assets/fi-flag.png";
import USFlag from "../assets/us-flag.png";

const NavBar = () => {
   const { language, switchLanguage } = useMainContext();
   const localization = useMainContext().localization.navbar;
   const location = useLocation();

   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

   // Update the `isMobile` state on window resize
   useEffect(() => {
      const handleResize = () => {
         setIsMobile(window.innerWidth <= 768);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
   }, []);

   const isActive = (path) => location.pathname === path;

   if (location.pathname === "/login" || location.pathname === "/dashboard") {
      return null;
   }
   if (location.pathname === "/login/" || location.pathname === "/dashboard/") {
      return null;
   }

   return (
      <header style={styles.header}>
         <div style={styles.navbarContainer}>
            {/* Logo */}
            <Link to="/" style={styles.logo}>
               Kaikkiluontoon
            </Link>

            {/* Hamburger Icon for Mobile */}
            {isMobile && (
               <button
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  style={styles.hamburger}
                  aria-label="Toggle Mobile Menu"
               >
                  ☰
               </button>
            )}

            {/* Navigation Links */}
            {!isMobile || isMobileMenuOpen ? (
               <nav
                  style={{
                     ...styles.navbar,
                     ...(isMobile
                        ? styles.navbarMobile
                        : {}), // Apply mobile-specific styles
                  }}
               >
                  <Link
                     style={{
                        ...styles.item,
                        ...(isActive(`/${language}/`) ? styles.itemActive : styles.itemInactive),
                     }}
                     to={`/${language}/`}
                     onClick={() => isMobile && setIsMobileMenuOpen(false)}
                  >
                     {localization.home}
                  </Link>


                  <div
                     style={styles.dropdownContainer}
                     onMouseEnter={() => setIsDropdownOpen(true)}
                     onMouseLeave={() => setIsDropdownOpen(false)}
                  >
                     <Link
                        to={`/${language}/for-businesses`}
                        style={{
                           ...styles.item,
                           cursor: 'pointer',
                           ...(isActive('/' + language + '/for-businesses') ? styles.itemActive : styles.itemInactive)
                        }}
                        onClick={() => isMobile && setIsMobileMenuOpen(false)}
                     >
                        {localization.forBusinesses}
                     </Link>
                     {isDropdownOpen && (
                        <div style={styles.dropdownMenu}>
                           <Link onClick={() => isMobile && setIsMobileMenuOpen(false)} style={styles.dropdownItem} to={`/${language}/programs`}>{localization.programs}</Link>
                           <Link onClick={() => isMobile && setIsMobileMenuOpen(false)} style={{ ...styles.dropdownItem, ...styles.divider }} to={`/${language}/destinations`}>{localization.destinations}</Link>
                           {/* <Link onClick={() => isMobile && setIsMobileMenuOpen(false)} style={{ ...styles.dropdownItem, ...styles.divider }} to={`/${language}/retreat`}>{localization.retreat}</Link> */}
                        </div>
                     )}
                  </div>

                  <Link
                     style={{
                        ...styles.item,
                        ...(isActive(`/${language}/our-guide`) ? styles.itemActive : styles.itemInactive),
                     }}
                     to={`/${language}/our-guide`}
                     onClick={() => isMobile && setIsMobileMenuOpen(false)}
                  >
                     {localization.ourGuide}
                  </Link>
                  <Link
                     style={{
                        ...styles.item,
                        ...(isActive(`/${language}/contact-info`) ? styles.itemActive : styles.itemInactive),
                     }}
                     to={`/${language}/contact-info`}
                     onClick={() => isMobile && setIsMobileMenuOpen(false)}
                  >
                     {localization.contactInfo}
                  </Link>

                  {/* Language Switcher */}
                  <button
                     onClick={() => switchLanguage(language === "fi" ? "en" : "fi")}
                     style={styles.languageButton}
                  >
                     <img
                        src={language === "fi" ? USFlag : FIFlag}
                        alt="language-flag"
                        style={styles.languageImage}
                     />
                  </button>
               </nav>
            ) : null}
         </div>
      </header>
   );
};

const styles = {
   header: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: "100%",
      padding: "20px 10%",
      backgroundColor: "rgba(255, 255, 255, 0.88)",
      boxShadow: "1px 4px 4px rgba(255, 255, 255, 0.88)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      zIndex: 100,
   },
   navbarContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
   },
   logo: {
      fontSize: "2rem",
      fontWeight: 700,
      color: "#DD9933",
      textDecoration: "none",
   },
   hamburger: {
      fontSize: "2rem",
      background: "none",
      border: "none",
      color: "black",
      cursor: "pointer",
   },
   navbar: {
      display: "flex",
      alignItems: "center",
   },
   navbarMobile: {
      flexDirection: "column",
      position: "fixed",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      gap: 15,
      backgroundColor: "rgba(0, 0, 0, 0.9)",
      zIndex: 100,
   },
   item: {
      fontSize: "1.2rem",
      color: "black",
      textDecoration: "none",
      marginLeft: 30,
      transition: "color 0.3s, transform 0.3s",
   },
   itemActive: {
      color: "#DD9933",
   },
   itemInactive: {
      color: "grey",
   },
   languageButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 30,
      background: "none",
      border: "none",
      cursor: "pointer",
   },
   languageImage: {
      width: "30px",
      height: "20px",
   },
   dropdownContainer: {
      position: 'relative',
   },
   divider: {
      borderTop: '1px solid rgba(255, 255, 255, 0.07)',
   },
   dropdownMenu: {
      position: 'absolute',
      top: '100%',
      left: '15%',
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      borderRadius: 5,
      zIndex: 10,
      width: '150px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
   },
   dropdownItem: {
      display: 'block',
      padding: '10px 20px',
      color: 'white',
      textDecoration: 'none',
      fontSize: '1rem',
      whiteSpace: 'nowrap',
      transition: 'background 0.3s',
   },
};

export default NavBar;
