import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Space from '../components/Space'

const Login = () => {
   const navigate = useNavigate()
   const [loginCredentials, setLoginCredentials] = useState({
      username: "",
      password: "",
   })
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState('')
   const [success, setSuccess] = useState(false)

   const handleInputChange = (e) => {
      const { name, value } = e.target
      setLoginCredentials((prev) => ({
         ...prev,
         [name]: value
      }))
   }

   const handleEnterPress = (e) => {
      if (e.key.toLowerCase() === 'enter') {
         handleLogin()
      }
   }

   const handleLogin = async () => {
      if (loginCredentials.username.length < 1 || loginCredentials.password.length < 1) {
         setError('Username and Password fields cannot be empty')
         return
      }

      setError('')
      setLoading(true)

      try {
         const response = await fetch("/api/actions.php", {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               action: 'authenticate',
               data: loginCredentials
            })
         })

         const result = await response.json()

         if (result.status === "error") {
            setLoading(false)
            setError(result.message)
            return
         }

         if (!response.ok) {
            setLoading(false)
            setError('An unexpected error occurred.')
            return
         }

         setLoading(false)
         navigate("/dashboard", { replace: true });
         window.location.reload()
      } catch (err) {
         setLoading(false)
         setError('An unexpected error occurred.')
      }
   }

   return (
      <div style={styles.container}>
         <h1>Admin Dashboard</h1>
         <div styles={styles.panel}>
            {/* Row 1: First Name and Last Name */}
            <div style={styles.formRow}>
               <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  style={styles.input}
                  value={loginCredentials.username}
                  onChange={handleInputChange}
                  onKeyDown={handleEnterPress}
                  required
               />
               <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  style={styles.input}
                  value={loginCredentials.password}
                  onChange={handleInputChange}
                  onKeyDown={handleEnterPress}
                  required
               />
            </div>

            {/* Submit Button */}
            <div style={styles.formRow}>
               <button style={styles.button} onClick={handleLogin} disabled={loading}>
                  {loading ? 'Logging In...' : 'Login'}
               </button>
            </div>

            <Space value={20} />

            {/* Error Message */}
            {error && <p style={styles.errorText}>{error}</p>}

            {/* Success Message */}
            {success && <p style={styles.successText}>Message sent successfully!</p>}
         </div>
      </div>
   )
}

const styles = {
   container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: "30px",
      width: '100vw',
      height: '100vh'
   },
   panel: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
   },
   formRow: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      gap: '10px',
   },
   input: {
      flex: 1,
      minWidth: '200px',
      padding: '10px',
      fontSize: '1rem',
      borderRadius: '5px',
      border: '1px solid #ccc',
   },
   button: {
      width: '100%',
      padding: '15px',
      marginTop: '20px',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#DD9933',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
   },
   errorText: {
      width: '100%',
      left: 0,
      position: 'absolute',
      color: 'red',
      fontSize: '1rem',
      textAlign: 'center',
   },
   successText: {
      width: '100%',
      left: 0,
      position: 'absolute',
      color: 'green',
      fontSize: '1rem',
      textAlign: 'center',
   }
}

export default Login