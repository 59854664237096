import React from 'react';
import Footer from '../components/Footer';
import Space from '../components/Space';

import { useMainContext } from '../context/MainContext';
import { Link, useParams } from 'react-router-dom';

import BackgroundImage from '../assets/background.jpg';

const TripView = () => {
   const { id } = useParams()
   const { trips } = useMainContext()
   const { language } = useMainContext()
   const localization = useMainContext().localization.pages.tripView

   console.log(trips)

   const trip = trips.find((trip) => trip.id == id)

   if (trip === undefined) {
      return (
         <div style={{ display: 'flex', gap: 20, flexDirection: 'column', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
            <h2>This Trip is not Available...</h2>
            <Link to="/" style={styles.bookButton}>Go Back</Link>
         </div>
      )
   }

   return (
      <div style={styles.container}>
         <div style={{ ...styles.top, backgroundImage: `url(${BackgroundImage})` }}></div>

         <Space value={80} />

         <div style={styles.middle}>
            <div>
               <h1>{JSON.parse(trip.title)[language]}</h1>

               <Space value={40} />
               <pre style={{ lineHeight: "1.4", whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                  {JSON.parse(trip.description)[language]}
               </pre>

               <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center' }}>

                  <Space value={60} />
                  <h4 style={{ color: '#333' }}>{localization.availableFrom} {trip.available_from}{trip.expires_on && ` - ${trip.expires_on}`}</h4>

                  <Space value={35} />
                  {trip.available === 1 && trip.expires_on && (() => {
                     // Parse the DD.MM.YYYY date
                     const [day, month, year] = trip.expires_on.split('.');
                     const expiresDate = new Date(`${year}-${month}-${day}`); // Convert to Date object
                     const currentDate = new Date();

                     // Check if the expiration date has passed
                     if (expiresDate >= currentDate) {
                        return (
                           <>
                              <h3 style={{ color: "#333" }}>{localization.interested}</h3>
                              <p style={{ color: "#333" }}>{localization.attentionText}</p>

                              <div style={{ display: "flex", gap: 10 }}>
                                 <Link to={`/${language}/contact-info`} style={styles.bookButton}>
                                    {localization.contactUs}
                                 </Link>
                                 <Link to={trip.book_link} style={styles.bookButton}>
                                    {localization.button}
                                 </Link>
                              </div>
                           </>
                        );
                     } else {
                        return (
                           <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center', gap: 10 }}>
                              <h3 style={{ color: "#333" }}>{localization.notAvailable}</h3>
                              <Link to={trip.book_link} style={{...styles.bookButton, maxWidth: 'fit-content'}}>
                                 {localization.button}
                              </Link>
                           </div>
                        );
                     }
                  })()}

               </div>

            </div>
         </div>

         <Space value={80} />

         <Footer />
      </div>
   );
};

const styles = {
   container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#E8E8E8',
   },
   top: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: 500,
      minWidth: '100%',
   },
   middle: {
      width: '65%',
   },
   bookButton: {
      color: '#F0F0F0', // Not pure white
      fontSize: '1.2rem',
      fontWeight: 600,
      textDecoration: 'none',
      backgroundColor: '#DD9933',
      padding: '10px 20px',
      borderRadius: '10px',
   }
}

export default TripView