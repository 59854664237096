import React from 'react'
import { useMainContext } from '../context/MainContext'
import { Link } from 'react-router-dom'
import Space from './Space'

import facebookImage from '../assets/facebook.jpeg'
import instagramImage from '../assets/instagram.jpeg'
import youtubeImage from '../assets/youtube.jpeg'

const Footer = () => {
   const localization = useMainContext().localization.footer

   return (
      <div style={styles.container}>
         <div style={styles.section}>
            <h3 style={styles.header}>{localization.firstSection.title}</h3>
            <Space value={10} />
            <p style={styles.text}>019 427 4700</p>
            <p style={styles.text}>toimisto@uudenmaanseuramatkat.fi</p>
            <p style={styles.text}>Uudenmaankatu 5-9</p>
            <p style={styles.text}>05800 HYVINKÄÄ</p>
         </div>
         <div style={styles.section}>
            <h3 style={styles.header}>{localization.secondSection.title}</h3>
            <Space value={10} />
            <p style={styles.text}>
               {localization.secondSection.content.first}
               <strong>{localization.secondSection.content.second}</strong>
               {localization.secondSection.content.third}
            </p>
         </div>
         <div style={styles.section}>
            <h3 style={styles.header}>{localization.thirdSection.title}</h3>
            <Space value={10} />
            <div style={styles.imageContainer}>
               <Link
                  to="https://www.facebook.com/uudenmaanseuramatkat"
                  style={{
                     ...styles.image,
                     backgroundImage: `url(${facebookImage})`,
                  }}
               ></Link>
               <Link
                  to="https://www.instagram.com/uudenmaanseuramatkat"
                  style={{
                     ...styles.image,
                     backgroundImage: `url(${instagramImage})`,
                  }}
               ></Link>
               <Link
                  to="https://www.youtube.com/uudenmaanseuramatkat"
                  style={{
                     ...styles.image,
                     backgroundImage: `url(${youtubeImage})`,
                  }}
               ></Link>
            </div>
         </div>
      </div>
   )
}

const styles = {
   container: {
      width: '100%',
      display: 'flex',
      flexDirection: `${window.innerWidth < 768 ? 'column' : 'row'}`,
      justifyContent: 'center',
      gap: `${window.innerWidth < 768 ? 50 : 150}px`,
      padding: 30,
      paddingBottom: 50,
      backgroundColor: '#F0F0F0',
   },
   section: {
      maxWidth: 400,
   },
   header: {
      color: '#333',
   },
   text: {
      color: '#4A4A4A',
   },
   imageContainer: {
      display: 'flex',
      gap: 10,
   },
   image: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
   },
}

export default Footer