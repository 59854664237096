import React from "react";

const Modal = ({
  isOpen,
  onCancel,
  onClose,
  children,
  showCancel = true,
  cancelText = "Cancel",
  showSave = true,
  saveText = "Save",
  saveColor = "green",
  cancelColor = "red",
  closeOnOverlayClick = false,
}) => {
  if (!isOpen) return null;

  const colorMap = {
    red: "#E53935",
    green: "#66BB6A",
    orange: "#DD9933",
  };

  const handleOverlayClick = (e) => {
    if (closeOnOverlayClick && e.target === e.currentTarget) {
      onCancel();
    }
  };

  return (
    <div
      onClick={handleOverlayClick}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          width: "100%",
          maxWidth: "700px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          maxHeight: "90vh",
          overflowY: "auto",
          padding: "20px",
        }}
      >
        {children}
        <div style={{ marginTop: "20px", display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          {showCancel && (
            <button
              onClick={onCancel}
              style={{
                backgroundColor: colorMap[cancelColor],
                color: "white",
                padding: "8px 12px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
              }}
            >
              {cancelText}
            </button>
          )}
          {showSave && (
            <button
              onClick={onClose}
              style={{
                backgroundColor: colorMap[saveColor],
                color: "white",
                padding: "8px 12px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
              }}
            >
              {saveText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
