import React from 'react'

const NotFound = () => {
   return (
      <div>
         <h1>Not Found Page</h1>
         <p>Information for not-found goes here.</p>
      </div>
   )
}

export default NotFound