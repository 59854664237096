import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Translations from './Translations.json'

// Supported languages and default language
const SUPPORTED_LANGUAGES = ['fi', 'en']
const DEFAULT_LANGUAGE = 'fi'
const IGNORED_PATHS = ['login', 'dashboard', 'uploads', 'uploads/', 'api']

// Context
const MainContext = createContext()

// Hook to use the context
export const useMainContext = () => useContext(MainContext)

// Provider Component
export const MainContextProvider = ({ children }) => {
   const navigate = useNavigate()
   const location = useLocation()
   const [language, setLanguage] = useState(DEFAULT_LANGUAGE)
   let localization = Translations[language]

   const [isAuthenticated, setIsAuthenticated] = useState(undefined)
   const [user, setUser] = useState(undefined)

   const [trips, setTrips] = useState(undefined)

   useEffect(() => {
      const pathSegments = location.pathname.split('/').filter(Boolean) // Split the path into segments
      const urlLang = pathSegments[0] // Assume the first segment is the language
      const remainingPath = pathSegments.slice(1).join('/') // Rest of the path after language


      // Check if the path is in IGNORED_PATHS
      if (IGNORED_PATHS.includes(pathSegments[0]) || IGNORED_PATHS.includes(pathSegments[1])) {
         // If the first segment is in IGNORED_PATHS, remove language if present
         if (IGNORED_PATHS.includes(pathSegments[1])) {
            console.log("Path: ", pathSegments[1])
            navigate(`/${pathSegments[1]}`, { replace: true })
            return
         }
         return
      }

      if (!SUPPORTED_LANGUAGES.includes(urlLang)) {
         localization = DEFAULT_LANGUAGE
         // Redirect to default language if the language is missing or invalid
         navigate(`/${DEFAULT_LANGUAGE}/${pathSegments.join('/')}`, { replace: true })
      } else {
         localization = urlLang
         // Set the language if it's valid
         setLanguage(urlLang)
      }
   }, [location.pathname, navigate])

   useEffect(() => {
      if (IGNORED_PATHS.includes(location.pathname.slice(1))) return;

      const fetchTrips = async () => {
         console.log("Trying to fetch trips")
         try {
            const response = await fetch('/api/actions.php', {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify({ action: 'view_trips' }),
            });

            const result = await response.json();

            // Handle server-side error response
            if (result.status === 'error') {
               setTrips(null)
               console.error(result.message);
               return;
            }

            // Check if response is OK
            if (!response.ok) {
               setTrips(null)
               console.error('HTTP error:', response.status, response.statusText);
               return;
            }

            // Update state with fetched trips
            setTrips(result.data);
         } catch (error) {
            console.error('Fetch error:', error.message);
         }
      };

      fetchTrips();
   }, []);

   useEffect(() => {
      const fetchAuthenticationStatus = async () => {
         try {
            const response = await fetch('/api/actions.php', {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify({ action: 'is_authenticated' }),
            });

            const result = await response.json();

            if (result.status === 'error') {
               setIsAuthenticated(false);
               console.error(result.message);
               return;
            }

            if (response.status === 401) {
               setIsAuthenticated(false);
               return;
            }

            setUser(result.username);
            setIsAuthenticated(true);
         } catch (error) {
            console.error('Fetch error:', error.message);
         }
      };

      fetchAuthenticationStatus()
   }, [])

   const switchLanguage = (newLang) => {
      if (SUPPORTED_LANGUAGES.includes(newLang)) {
         const pathSegments = location.pathname.split('/').filter(Boolean)

         // Update the URL while preserving the current path
         const [, ...remainingPath] = pathSegments
         localization = Translations[newLang]
         navigate(`/${newLang}/${remainingPath.join('/')}`)
      }
   }

   console.log(location.pathname.slice(1))
   if (trips == undefined && !IGNORED_PATHS.includes(location.pathname.slice(1))) { return }
   if (isAuthenticated === undefined && IGNORED_PATHS.includes(location.pathname.slice(1))) { return }
   if (isAuthenticated === true && (location.pathname.slice(1) == "login" || location.pathname.slice(1) == "login/")) {
      navigate("/dashboard")
      return
   }
   if (isAuthenticated === false && (location.pathname.slice(1) == "dashboard" || location.pathname.slice(1) == "dashboard/")) {
      navigate("/login")
      return
   }

   return (
      <MainContext.Provider value={{ isAuthenticated, user, trips, localization, language, switchLanguage }}>
         {children}
      </MainContext.Provider>
   )
}
