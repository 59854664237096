import React, { useState } from 'react'
import { useMainContext } from '../context/MainContext'
import Footer from '../components/Footer'
import Space from '../components/Space'
import BackgroundImage from '../assets/contact-info-background.jpeg'
import { Link } from 'react-router-dom'

// Additional image imports for the 3x3 grid
import BelowImage1 from '../assets/contact-info/below-image-1.png'
import BelowImage2 from '../assets/contact-info/below-image-2.png'
import BelowImage3 from '../assets/contact-info/below-image-3.png'
import BelowImage4 from '../assets/contact-info/below-image-4.png'
import BelowImage5 from '../assets/contact-info/below-image-5.png'
import BelowImage6 from '../assets/contact-info/below-image-6.png'
import BelowImage7 from '../assets/contact-info/below-image-7.png'
import BelowImage8 from '../assets/contact-info/below-image-8.png'
import BelowImage9 from '../assets/contact-info/below-image-9.png'

const ContactInfo = () => {
   const [formData, setFormData] = useState({
      firstname: '',
      lastname: '',
      email: '',
      trip: '',
      message: '',
   })
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState('')
   const [success, setSuccess] = useState(false)

   const { trips } = useMainContext()
   const { language } = useMainContext()
   const localization = useMainContext().localization.pages.contactInfo

   const handleInputChange = (e) => {
      const { name, value } = e.target
      setFormData((prev) => ({
         ...prev,
         [name]: value,
      }))
   }

   const handleSubmit = async (e) => {
      e.preventDefault()
      setLoading(true)
      setError('')
      setSuccess(false)

      try {
         // Replace with your API endpoint
         const response = await fetch('/api/actions.php', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               action: 'receive_client_message',
               data: formData,
            }),
         })

         const result = await response.json()

         if (!response.ok) {
            setError('Failed to Send Message: ' + result.message)
            return
         }

         setSuccess(true)
         setError('')
         setFormData({ firstname: '', lastname: '', email: '', trip: '', message: '' })
      } catch (err) {
         console.log(err)
         setError('An unexpected error occurred. ')
      } finally {
         setLoading(false)
      }
   }

   console.log("Hello")


   return (
      <div style={styles.container}>
         <div style={{ ...styles.top, backgroundImage: `url(${BackgroundImage})` }}></div>

         <Space value={80} />

         <div style={styles.middle}>
            <div>
               <h1>Yhteystiedot</h1>
               <Space value={30} />

               <h3>KAIKKILUONTOON.FI</h3>
               <Space value={15} />

               <p>
                  <span>{localization.content[0]} </span>
                  <Link
                     to="https://uudenmaanseuramatkat.fi/"
                     style={styles.linkText}
                  >
                     Uudenmaan Seuramatkat Oy
                  </Link>
               </p>

               <Space value={20} />
               <p>
                  {localization.content[1]}
               </p>

               <Space value={20} />
               <p>
                  {localization.content[2]}
               </p>

               <Space value={20} />
               <p>
                  {localization.content[3]}
               </p>

               <Space value={20} />
               <p>
                  <span>{localization.content[4]} </span>
                  <Link to="/" style={styles.linkText}>
                     <span>{localization.content[5]}</span>
                  </Link>
               </p>

               <Space value={40} />
               <h3>{localization.content[6]}</h3>
               <Space value={20} />
               <h4>{localization.content[7]}</h4>
               <Space value={10} />

               <p style={styles.mutedText}>{localization.content[8]}</p>
               <Space value={5} />
               <p style={styles.mutedText}>{localization.content[9]}</p>
               <Space value={5} />
               <p style={styles.mutedText}>{localization.content[10]}</p>
               <Space value={5} />
               <p style={styles.mutedText}>{localization.content[11]}</p>

               <Space value={15} />
               <Link
                  to="https://uudenmaanseuramatkat.fi/"
                  style={styles.linkText}
               >
                  www.uudenmaanseuramatkat.fi
               </Link>

               <Space value={40} />

               <h3>{localization.form.title}</h3>

               <Space value={15} />
               <form style={styles.form} onSubmit={handleSubmit}>
                  {/* Row 1: First Name and Last Name */}
                  <div style={styles.formRow}>
                     <input
                        type="text"
                        name="firstname"
                        placeholder={localization.form.firstname}
                        style={styles.input}
                        value={formData.firstname}
                        onChange={handleInputChange}
                        required
                     />
                     <input
                        type="text"
                        name="lastname"
                        placeholder={localization.form.lastname}
                        style={styles.input}
                        value={formData.lastname}
                        onChange={handleInputChange}
                        required
                     />
                  </div>

                  {/* Row 2: Email and Select */}
                  <div style={styles.formRow}>
                     <input
                        type="email"
                        name="email"
                        placeholder={localization.form.email}
                        style={styles.input}
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                     />
                     <select
                        name="trip"
                        style={styles.select}
                        value={formData.trip}
                        onChange={handleInputChange}
                     >
                        <option value="0">{localization.form.trip}</option>
                        {trips && trips.map((trip) => (
                           <option key={trip.id} value={JSON.parse(trip.title)[language]}>
                              {JSON.parse(trip.title)[language]}
                           </option>
                        ))}
                     </select>
                  </div>

                  {/* Row 3: Message */}
                  <div style={styles.formRow}>
                     <textarea
                        name="message"
                        placeholder={localization.form.message}
                        style={styles.textarea}
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                     ></textarea>
                  </div>

                  {/* Submit Button */}
                  <div style={styles.formRow}>
                     <button type="submit" style={styles.button} disabled={loading}>
                        {loading ? localization.form.send.sending : localization.form.send.send}
                     </button>
                  </div>

                  {/* Error Message */}
                  {error && <p style={styles.errorText}>{error}</p>}

                  {/* Success Message */}
                  {success && <p style={styles.successText}>{localization.form.send.sent}</p>}
               </form>

               <Space value={100} />

            </div>
         </div>

         {/* 3x3 Image Grid */}
         <div style={styles.imageGrid}>
            <img src={BelowImage1} alt="Image 1" style={styles.image} />
            <img src={BelowImage2} alt="Image 2" style={styles.image} />
            <img src={BelowImage3} alt="Image 3" style={styles.image} />
            <img src={BelowImage4} alt="Image 4" style={styles.image} />
            <img src={BelowImage5} alt="Image 5" style={styles.image} />
            <img src={BelowImage6} alt="Image 6" style={styles.image} />
            <img src={BelowImage7} alt="Image 7" style={styles.image} />
            <img src={BelowImage8} alt="Image 8" style={styles.image} />
            <img src={BelowImage9} alt="Image 9" style={styles.image} />
         </div>

         <Space value={0} />
         <Footer />
      </div>
   )
}

const styles = {
   container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#E8E8E8',
   },
   top: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: 500,
      minWidth: '100%',
   },
   middle: {
      width: '65%',
   },
   linkText: {
      color: '#DD9933',
      textDecoration: 'underline',
   },
   mutedText: {
      color: '#4A4A4A',
   },
   form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      width: '100%',
   },
   formRow: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
   },
   input: {
      flex: 1,
      minWidth: '200px',
      padding: '10px',
      fontSize: '1rem',
      borderRadius: '5px',
      border: '1px solid #ccc',
   },
   select: {
      flex: 1,
      minWidth: '200px',
      padding: '10px',
      fontSize: '1rem',
      borderRadius: '5px',
      border: '1px solid #ccc',
      backgroundColor: 'white',
   },
   textarea: {
      flex: 1,
      width: '100%',
      padding: '10px',
      fontSize: '1rem',
      borderRadius: '5px',
      border: '1px solid #ccc',
      height: '150px',
   },
   button: {
      width: '100%',
      padding: '15px',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#DD9933',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
   },
   errorText: {
      color: 'red',
      fontSize: '1rem',
      textAlign: 'center',
   },
   successText: {
      color: 'green',
      fontSize: '1rem',
      textAlign: 'center',
   },
   imageGrid: {
      padding: 20,
      width: '65%',
      display: 'grid',
      borderRadius: 10,
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: 10,
   },
   image: {
      width: '100%',
      borderRadius: 10,
      height: 'auto',
   },
}

export default ContactInfo