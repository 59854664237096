import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import NavBar from './components/NavBar'
import Home from './pages/Home'

import ForBusinesses from './pages/ForBusinesses'
import Programs from './pages/Programs'
import Destinations from './pages/Destinations'
import Retreat from './pages/Retreat'

import OurGuide from './pages/OurGuide'
import ContactInfo from './pages/ContactInfo'
import NotFound from './pages/NotFound'

import TripView from './pages/TripView'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'

import { MainContextProvider } from './context/MainContext'

function App() {
	return (
		<Router>
			<MainContextProvider>
				<NavBar />
				<Routes>
					{/* Public Routes */}
					<Route path="/:lang/" element={<Home />} />

					<Route path="/:lang/for-businesses" element={<ForBusinesses />} />
					<Route path="/:lang/programs" element={<Programs />} />
					<Route path="/:lang/destinations" element={<Destinations />} />
					{/* <Route path="/:lang/retreat" element={<Retreat />} /> */}
					
					<Route path="/:lang/our-guide" element={<OurGuide />} />
					<Route path="/:lang/contact-info" element={<ContactInfo />} />

					{/* Fallback to NotFound */}
					<Route path="*" element={<NotFound />} />

					{/* Hidden Public Routes */}
					<Route path="/login" element={<Login />} />
					<Route path="/:lang/trip-view/:id" element={<TripView />} />

					{/* Private Routes */}
					<Route path="/dashboard" element={<Dashboard />} />
				</Routes>
			</MainContextProvider>
		</Router>
	)
}

export default App
