import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Space from '../components/Space';

import { useMainContext } from '../context/MainContext';

import BackgroundImage from '../assets/for-businesses-background.jpeg';
import BackgroundImage1 from '../assets/destinations-image-1.jpg';
import BackgroundImage2 from '../assets/destinations-image-2.jpg';
import BackgroundImage3 from '../assets/destinations-image-3.jpg';

const Destinations = () => {
   const localization = useMainContext().localization.pages.destinations;

   return (
      <div style={styles.container}>
         <div style={{ ...styles.top, backgroundImage: `url(${BackgroundImage})` }}></div>

         <Space value={80} />

         <div style={styles.middle}>
            <div>
               <h1>{localization.title}</h1>

               <Space value={30} />
               <p>
                  {localization.content.first}
               </p>

               <Space value={20} />
               <p>{localization.content.firstList[0]}</p>
               <Space value={2} />
               <p>{localization.content.firstList[1]}</p>
               <Space value={2} />
               <p>{localization.content.firstList[2]}</p>
               <Space value={2} />
               <p>{localization.content.firstList[3]}</p>
               <Space value={2} />
               <p>{localization.content.firstList[4]}</p>
            </div>

            <Space value={40} />

            <div style={styles.imagesContainer}>
               {/* First row with two images */}
               <div style={styles.iContainerFirst}>
                  <div style={{ ...styles.image, backgroundImage: `url(${BackgroundImage1})` }}></div>
                  <div style={{ ...styles.image, backgroundImage: `url(${BackgroundImage2})` }}></div>
               </div>
               {/* Second row with one image */}
               <div style={styles.iContainerSecond}>
                  <div style={{ ...styles.image, height: 450, backgroundImage: `url(${BackgroundImage3})` }}></div>
               </div>
            </div>
         </div>

         <Space value={80} />

         <Footer />
      </div>
   );
};

const styles = {
   container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#E8E8E8',
   },
   top: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: 500,
      minWidth: '100%',
   },
   middle: {
      width: '65%',
   },
   linkText: {
      color: '#DD9933',
      textDecoration: 'underline',
   },
   imagesContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: 20,
   },
   iContainerFirst: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr', // Two columns
      gap: 20,
   },
   iContainerSecond: {
      display: 'grid',
      gridTemplateColumns: '1fr', // Single column
   },
   image: {
      width: '100%',
      height: 350, // Adjust as needed
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: 10, // Optional rounded corners
   },
};

export default Destinations;
