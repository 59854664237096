import React from "react";

const Table = ({ data, columns, onView, onModify, onDelete, activeSection }) => {
  const transformCell = (col, val) => {
    if (activeSection === 0) {
      // Trips section
      if (col === "title" || col === "description") {
        try {
          const obj = JSON.parse(val || "{}");
          return obj.en || "";
        } catch {
          return "";
        }
      } else if (col === "hidden" || col === "available") {
        return (val == 1 || val === "1") ? "Yes" : "No";
      }
    }
    return val;
  };

  const cellStyle = (col) => {
    // Description may have multiline text, pre-wrap it
    if (col === "description" && activeSection === 0) {
      return {
        padding: "10px",
        borderBottom: "1px solid #ddd",
        whiteSpace: "normal", // Allow wrapping
        overflow: "hidden", // Hide overflow
        display: "-webkit-box", // Use flexbox for clamping
        WebkitBoxOrient: "vertical", // Vertical orientation
        WebkitLineClamp: 4, // Limit to 4 lines
        textOverflow: "ellipsis", // Add "..." at the end
        verticalAlign: "middle",
        textAlign: "center",
      };
    }
    return {
      padding: "10px",
      borderBottom: "1px solid #ddd",
      verticalAlign: "middle",
      textAlign: "center",
    };
  };

  return (
    <div
      style={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        overflowX: "auto", // Enable horizontal scrolling if content overflows
        marginBottom: "20px", // Add some space below the table
      }}
    >
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            {columns.map((col) => (
              <th
                key={col}
                style={{
                  padding: "10px",
                  textAlign: "center",
                  backgroundColor: "#f4f4f4",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                {col.charAt(0).toUpperCase() + col.slice(1)}
              </th>
            ))}
            <th
              style={{
                padding: "10px",
                textAlign: "center",
                backgroundColor: "#f4f4f4",
                whiteSpace: "nowrap",
              }}
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map((col) => (
                <td key={col} style={cellStyle(col)}>
                  {transformCell(col, row[col])}
                </td>
              ))}
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  borderBottom: "1px solid #ddd",
                  whiteSpace: "nowrap",
                }}
              >
                {onView && (
                  <button
                    style={{
                      marginRight: "5px",
                      backgroundColor: "#66BB6A",
                      color: "white",
                      padding: "5px 10px",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => onView(row)}
                  >
                    View
                  </button>
                )}
                {onModify && (
                  <button
                    style={{
                      marginRight: "5px",
                      backgroundColor: "#DD9933",
                      color: "white",
                      padding: "5px 10px",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => onModify(row)}
                  >
                    Modify
                  </button>
                )}
                {onDelete && (
                  <button
                    style={{
                      backgroundColor: "#E53935",
                      color: "white",
                      padding: "5px 10px",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => onDelete(row)}
                  >
                    Delete
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
