import React from 'react';
import Footer from '../components/Footer';
import Space from '../components/Space';

import BackgroundImage from '../assets/our-guide-background.jpeg';
import { Link } from 'react-router-dom';
import { useMainContext } from '../context/MainContext';

const OurGuide = () => {
   const localization = useMainContext().localization.pages.ourGuide;

   return (
      <div style={styles.container}>
         <div style={{ ...styles.top, backgroundImage: `url(${BackgroundImage})` }}></div>

         <Space value={80} />

         <div style={styles.middle}>
            <div>
               <h1>{localization.title}</h1>
               
               <Space value={30} />
               <h3>{localization.content[0]}</h3>
               
               <Space value={15} />
               <p>{localization.content[1]}</p>
               
               
               <Space value={40} />
               <h4>{localization.content[2]}</h4>
            
               <Space value={15} />
               <p>{localization.content[3]}</p>
               
               <Space value={5} />
               <p>{localization.content[4]}</p>
            
               
               <Space value={40} />
               <h4>{localization.content[5]}</h4>
            
               <Space value={15} />
               <p>{localization.content[6]}</p>
            

               <Space value={40} />
               <h4>{localization.content[7]}</h4>
            
               <Space value={15} />
               <p>{localization.content[8]}</p>            
            

               <Space value={60} />
               <p>{localization.content[9]}</p>

               <Space value={5} />
               <Link
                  to="https://www.hyvinkaa.fi/kulttuuri-ja-vapaa-aika/liikunta/virkistys-ja-ulkoilu1/terveysmetsa/"
                  style={styles.linkText}
               >Tutustu Terveysmetsään</Link>

               <Space value={5} />
               <Link
                  to="https://www.facebook.com/hyvinkaanterveysmetsa/"
                  style={styles.linkText}
               >Terveysmetsä Facebook</Link>

               <Space value={5} />
               <Link
                  to="http://kytaja-usmi.fi/"
                  style={styles.linkText}
               >Kytäjä-Usmi</Link>

               <Space value={5} />
               <Link
                  to="https://visithyvinkaa.fi/sveitsi/info-yhteystiedot/luonto/"
                  style={styles.linkText}
               >Sveitsi</Link>
            </div>
         </div>

         <Space value={100} />

         <Footer />
      </div>
   );
};

const styles = {
   container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#E8E8E8',
   },
   top: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: 500,
      minWidth: '100%',
   },
   middle: {
      width: '65%',
   },

   linkText: {
      color: "#DD9933",
      textDecoration: 'underline',
   }
};

export default OurGuide;