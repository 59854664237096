import React from 'react'
import { Link } from 'react-router-dom'

const Card = ({ image, redirect, id }) => {
   return (
      <div style={styles.container}>
         <div style={{ ...styles.image, backgroundImage: `url(${image})` }}>

            <Link to={{ pathname: redirect + `/${id}` }} style={styles.button}>Tutustu</Link>
         </div>
      </div>
   )
}

const styles = {
   container: {
      width: '100%',
      maxWidth: 400,
      aspectRatio: '1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 20,
      overflow: 'hidden',
      position: 'relative', // Ensure the container is positioned relative for the button to be placed within it
   },

   image: {
      width: '100%',
      aspectRatio: '1',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: "#0000",
   },

   button: {
      position: 'absolute',
      bottom: 10,
      right: 10, 
      cursor: 'pointer',
      color: '#F0F0F0',
      fontSize: '1.2rem',
      fontWeight: 600,
      textDecoration: 'none',
      backgroundColor: '#DD9933',
      padding: '10px 20px',
      borderRadius: '10px',
   },
};

export default Card